import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { TinyMenuSdkData } from '@wix/thunderbolt-components';
import {
  ITinyMenuProps,
  ITinyMenuSDK,
  ITinyMenuOwnSDKFactory,
} from '../TinyMenu.types';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import { createAccountNavBarSdkFactory } from '../../LoginSocialBar/corvid/LoginSocialBar.corvid';

type AccountNavBarApi = Parameters<
  ReturnType<typeof createAccountNavBarSdkFactory>
>[0];
type AccountNavBarSetProps = AccountNavBarApi['setProps'];
type AccountNavBarSetPropsParam = Parameters<AccountNavBarSetProps>[0];

const sdkFactory: ITinyMenuOwnSDKFactory = api => {
  const { props, sdkData, setProps } = api;
  const accountNavBarSetProps: AccountNavBarSetProps = value => {
    if (!sdkData.members) {
      return;
    }

    const updateMembersProps = (newProps: AccountNavBarSetPropsParam) => {
      setProps({
        members: {
          ...props.members,
          ...newProps,
        },
      });
    };

    // Handle props as Promise value type
    if (Promise.resolve(value) === value) {
      value.then(updateMembersProps);
    } else {
      updateMembersProps(value);
    }
  };

  const accountNavBar = createAccountNavBarSdkFactory({ isMobileOnly: true })({
    ...api,
    props: props.members,
    sdkData: sdkData.members ?? {
      menuItems: [],
      navBarItems: [],
    },
    setProps: accountNavBarSetProps,
  } as AccountNavBarApi);

  return {
    get accountNavBar() {
      return accountNavBar;
    },
  };
};

export const sdk = composeSDKFactories<
  ITinyMenuProps,
  ITinyMenuSDK,
  TinyMenuSdkData
>(
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  sdkFactory,
);
