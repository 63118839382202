import { MouseEventHandler } from 'react';
import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { basePropsSDKFactory } from './basePropsSDKFactory';
import { viewportPropsSDKFactory } from './viewportSDKFactory';

export interface IElementPropsSDKProps {}

export interface IElementPropsSDK {
  onMouseIn: (cb: MouseEventHandler) => void;
  onMouseOut: (cb: MouseEventHandler) => void;
  toJSON(): { id: string; type: string; global: boolean; rendered: boolean };
  rendered: boolean;
}
export interface IElementPropsSDKActions {
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const _elementPropsSDKFactory: CorvidSDKPropsFactory<
  IElementPropsSDKProps,
  IElementPropsSDK
> = ({ registerEvent, metaData }) => ({
  onMouseIn(cb: MouseEventHandler) {
    registerEvent('onMouseEnter', cb);
  },

  onMouseOut(cb: MouseEventHandler) {
    registerEvent('onMouseLeave', cb);
  },

  get rendered() {
    return metaData.isRendered();
  },

  toJSON() {
    return {
      id: metaData.compId,
      type: `$w.${metaData.compType}`,
      global: metaData.isGlobal(),
      rendered: metaData.isRendered(),
    };
  },
});

export const elementPropsSDKFactory = composeSDKFactories(
  basePropsSDKFactory,
  viewportPropsSDKFactory,
  withValidation(_elementPropsSDKFactory, {
    type: ['object'],
    properties: {
      onMouseIn: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onMouseOut: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
    },
  }),
);
