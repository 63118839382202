import {
  withValidation,
  composeSDKFactories,
  assert,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import { TextInputType } from '@wix/thunderbolt-components-native/src/components/TextInput/types';
import {
  ITextInputProps,
  ITextInputOwnSDKFactory,
  ITextInputSDK,
  ITextInputImperativeActions,
} from '../TextInput.types';
import {
  readOnlyPropsSDKFactory,
  createValidationPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValuePropsSdkFactory,
  textInputPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  createStylePropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  createInputValidator,
  composeSanitizers,
  numberToString,
  emptyStringIfNotString,
  removeLineBreaks,
  forceMaxLength,
  removeLeadingAndTrailingWhitespace,
  validFloatingPointNumber,
  normalizePrecision,
  Sanitizer,
  InputValidator,
} from '../../../core/corvid/inputUtils';
import { validateTextInput } from './utils/validateTextInput';

const textInputValidator: InputValidator<
  ITextInputProps,
  ITextInputImperativeActions
> = createInputValidator(validateTextInput);

const validationPropsSDKFactory = createValidationPropsSDKFactory<
  ITextInputProps,
  ITextInputImperativeActions
>(textInputValidator);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory<
  ITextInputProps,
  ITextInputImperativeActions
>(textInputValidator);

const valuePropsSDKFactory = createValuePropsSdkFactory<
  ITextInputProps,
  ITextInputImperativeActions
>(
  (value, props) => valueSanitizers(props)(value),
  { type: ['string'] },
  textInputValidator,
);

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

const _ownSDKFactory: ITextInputOwnSDKFactory = api => {
  const { setProps, props } = api;

  return {
    get max() {
      return props.max;
    },
    set max(value) {
      if (value === undefined || value === null) {
        setProps({ max: null });
      }

      setProps({ max: value });

      textInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get min() {
      return props.min;
    },
    set min(value) {
      if (value === undefined || value === null) {
        setProps({ min: null });
      }

      setProps({ min: value });

      textInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get inputType() {
      return props.inputType;
    },
    set inputType(value) {
      setProps({ inputType: value });

      textInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
  };
};

const ownSDKFactory = withValidation(
  _ownSDKFactory,
  {
    type: ['object'],
    properties: {
      min: { type: ['integer', 'nil'] },
      max: { type: ['integer', 'nil'] },
      inputType: {
        type: ['string'],
        enum: ['text', 'email', 'number', 'password', 'tel', 'url'],
      },
    },
  },
  {
    max: [
      (value, api) => {
        if (
          !assert.isNil(value) &&
          assert.isInteger(api.props.min) &&
          !createCompSchemaValidator(api.metaData.compId)(
            value,
            { type: ['integer'], minimum: api.props.min },
            'max',
          )
        ) {
          return false;
        }

        return true;
      },
    ],
    min: [
      (value, api) => {
        if (
          !assert.isNil(value) &&
          assert.isInteger(api.props.max) &&
          !createCompSchemaValidator(api.metaData.compId)(
            value,
            { type: ['integer'], maximum: api.props.max },
            'min',
          )
        ) {
          return false;
        }
        return true;
      },
    ],
  },
);

const valueSanitizers = ({ inputType, maxLength, step }: ITextInputProps) => {
  const sanitizers: { [key in TextInputType]: Sanitizer } = {
    text: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      removeLineBreaks,
      forceMaxLength(maxLength),
    ]),
    password: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      removeLineBreaks,
      forceMaxLength(maxLength),
    ]),
    number: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      validFloatingPointNumber,
      normalizePrecision(step),
    ]),
    email: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      removeLineBreaks,
      removeLeadingAndTrailingWhitespace,
    ]),
    url: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      removeLineBreaks,
      removeLeadingAndTrailingWhitespace,
    ]),
    tel: composeSanitizers([
      numberToString,
      emptyStringIfNotString,
      removeLineBreaks,
    ]),
  };
  return sanitizers[inputType];
};

export const sdk = composeSDKFactories<ITextInputProps, ITextInputSDK, any>(
  ownSDKFactory,
  requiredPropsSDKFactory,
  readOnlyPropsSDKFactory,
  textInputPropsSDKFactory,
  focusPropsSDKFactory,
  valuePropsSDKFactory,
  validationPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  clickPropsSDKFactory,
  stylePropsSDKFactory,
  collapsedPropsSDKFactory,
);
