import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import {
  withValidation,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';

type FocusEventHandler = (e: React.FocusEvent) => void;

export interface IFocusPropSDKProps {}

export interface IFocusPropSDK {
  focus: () => void;
  blur: () => void;
  onFocus: (handler: FocusEventHandler) => void;
  onBlur: (handler: FocusEventHandler) => void;
}

export interface IFocusPropsSDKActions {
  onFocus: (event: React.FocusEvent) => void;
  onBlur: (event: React.FocusEvent) => void;
}

export interface IFocusPropsImperativeActions {
  focus: () => void;
  blur: () => void;
}

const _focusPropsSDKFactory: CorvidSDKPropsFactory<
  IFocusPropSDKProps,
  IFocusPropSDK,
  IFocusPropsImperativeActions
> = ({ registerEvent, compRef, metaData }) => {
  const schemaValidator = createCompSchemaValidator(metaData.compId);
  return {
    focus() {
      compRef.focus();
    },
    blur() {
      compRef.blur();
    },
    onFocus(handler: FocusEventHandler) {
      const isValid = schemaValidator(
        handler,
        { type: ['function'] },
        'onFocus',
      );
      if (!isValid) {
        return;
      }
      registerEvent('onFocus', handler);
    },
    onBlur(handler: FocusEventHandler) {
      const isValid = schemaValidator(
        handler,
        { type: ['function'] },
        'onBlur',
      );
      if (!isValid) {
        return;
      }
      registerEvent('onBlur', handler);
    },
  };
};

export const focusPropsSDKFactory = withValidation(_focusPropsSDKFactory, {
  type: ['object'],
  properties: {
    onFocus: {
      type: ['function'],
    },
    onBlur: {
      type: ['function'],
    },
  },
});
